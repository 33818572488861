import React, { useContext } from "react";
import "../assets/scss/MyInfo.scss";
import { TypeAnimation } from "react-type-animation";
import { CODE_MSG, getMessageByCode } from "../utils/Tools";
import { LanguageContext } from "../pages/HomePage";
export const MyInfo: React.FC = () => {
  return (
    <div className="h-full flex-grow flex">
      {
        <div className=" my-auto h-full flex-grow flex flex-col items-center font-extrabold md:text-2xl md:gap-y-10 content-center text-white  py-5 w-full  min-h-18">
          <TypeAnimation
            style={{
              whiteSpace: "pre-line",
              textShadow: "1px 1px 2px #000",
              display: "flex",
              fontSize: "2em",
            }}
            sequence={[
              getMessageByCode(CODE_MSG.INFO_DEF_NOM, "FR"),
              1000,
              getMessageByCode(CODE_MSG.INFO_DEF_METIER, "FR"),
              1000,
            ]}
            speed={50}
            repeat={Infinity}
          />
          <TypeAnimation
            style={{
              whiteSpace: "pre-line",
              textShadow: "1px 1px 2px #000",
              display: "flex",
              fontSize: "2em",
              textAlign: "center",
            }}
            sequence={[
              1000,
              getMessageByCode(CODE_MSG.INFO_DESCREPTION, "FR"),
              1000,
              "",
            ]}
            speed={50}
            repeat={Infinity}
          />
        </div>
      }
    </div>
  );
};
