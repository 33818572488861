import React from "react";
import { MdFacebook, MdMail } from "react-icons/md";
import { FaInstagram, FaPhoneAlt, FaLinkedin } from "react-icons/fa";
import { CustomIcon } from "./CustomIcon";
import { Link } from "@chakra-ui/react";

export const Header: React.FC = () => {
  return (
    <div className="mt-2 flex flex-col md:flex-row justify-around w-full  opacity-80 text-white bg-black">
      <div className="flex flex-row justify-around gap-5 p-5">
        <Link href="https://www.linkedin.com/in/errahali/">
          <span>
            {" "}
            <CustomIcon iconType={FaLinkedin} />
          </span>
        </Link>

        <span>
          {" "}
          <CustomIcon iconType={MdFacebook} />
        </span>
        <span>
          <CustomIcon iconType={FaInstagram} />
        </span>
      </div>
      <div className="flex flex-col gap-2 md:flex-row md:gap-4 items-center justify-center   md:justify-between md:items-baseline my-auto m-2">
        <p className="flex flex-wrap gap-2 break-words flex-grow ">
          <CustomIcon iconType={MdMail} /> mohamederrahali34@gmail.com
        </p>
        <p className="p-2 flex gap-2 flex-wrap justify-center  ">
          <CustomIcon iconType={FaPhoneAlt} /> +33 7 80 83 08 18
        </p>
      </div>
    </div>
  );
};
