import { CheckIcon } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";
import React from "react";

export interface DomaineCompetanceProps {
  title: string;
  description: string;
}
export const DomaineCompetance: React.FC<DomaineCompetanceProps> = ({
  title,
  description,
}) => {
  return (
    <div className="">
      <div className="uppercase font-bold flex flex-row text-gray-500  justify-start items-center gap-2">
        {" "}
        <Icon
          boxSize={4}
          as={CheckIcon}
          color={"white"}
          style={{
            backgroundColor: "orangered",
            borderRadius: "100%",
          }}
        />{" "}
        {title}
      </div>
      <div className="pl-2 ">{description}</div>
    </div>
  );
};
