import React, { useContext } from "react";
import { DomaineCompetance } from "../components/DomaineCompetance";
import { Competance } from "../components/Competance";
import {
  CODE_MSG,
  getMesCompétances,
  getMesDomainesCompetances,
  getMessageByCode,
} from "../utils/Tools";
import { LanguageContext } from "./HomePage";

export const MesCompetances: React.FC = () => {
  const language = useContext(LanguageContext);
  return (
    <div
      id="skills"
      className="p-4 my-auto flex flex-col items-center  md:pt-14  gap-y-6 h-full  min-h-screen"
    >
      <div className="uppercase font-bold text-3xl text-black  text-center">
        {getMessageByCode(CODE_MSG.MES_COMPETANCES_TITLE, language)}
      </div>
      <div className="font-bold text-2xl  text-gray-500  text-center">
        {getMessageByCode(CODE_MSG.MES_COMPETANCES_DESCREPTION, language)}
      </div>
      <div className="flex flex-col md:flex-row gap-4 justify-between w-full md:px-4">
        <div className="text-gray-500">
          <div className="font-bold text-xl  my-4">
            {" "}
            {getMessageByCode(CODE_MSG.DOMAINE_COMPETANCE, language)}
          </div>
          <div className="text-black pl-2 flex flex-col gap-2">
            {getMesDomainesCompetances(language).map((item, index: number) => {
              return (
                <DomaineCompetance
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              );
            })}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="font-bold text-xl  my-4 text-gray-500">
            {getMessageByCode(CODE_MSG.COMPETANCES_EN_DEVELOPPEMENT, language)}
          </div>
          {getMesCompétances(language).map((item, index: number) => {
            return (
              <Competance
                key={index}
                title={item.title}
                technologies={item.technologies}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
