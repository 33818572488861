import { CheckIcon } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export interface Technologie {
  icon: ReactNode;
  title: string;
}
export interface CompetanceProps {
  title: string;
  technologies: Technologie[];
}
export const Competance: React.FC<CompetanceProps> = ({
  title,
  technologies,
}) => {
  return (
    <div className="flex flex-row items-center justify-start gap-4   ">
      <div className="uppercase font-bold flex flex-row text-gray-500  justify-start items-center gap-2">
        <Icon
          boxSize={4}
          as={CheckIcon}
          color={"white"}
          style={{
            backgroundColor: "green",
            borderRadius: "100%",
          }}
        />{" "}
        {title}
      </div>
      <div className="flex flex-wrap   md:flex-row  gap-4 justify-start">
        {technologies.map((item, index: number) => {
          return (
            <div key={index}>
              <div className="flex flex-col justify-center items-center">
                {item.icon}
              </div>
              <div className="font-bold">{item.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
