import React, { useContext } from "react";
import { CODE_MSG, getMessageByCode } from "../utils/Tools";
import { LanguageContext } from "./HomePage";
export const Apropos: React.FC = () => {
  const language = useContext(LanguageContext);
  return (
    <div
      id="about"
      className="my-auto flex flex-col items-center  md:pt-14  gap-y-6 h-full  min-h-screen"
    >
      <div className="uppercase font-bold text-3xl text-black">
        {getMessageByCode(CODE_MSG.APROPOS_TITLE, language)}
      </div>
      <div className="font-bold text-2xl  text-gray-500">
        {getMessageByCode(CODE_MSG.APROPOS_DESCREPTION, language)}
      </div>
      <div className="flex flex-row gap-4 justify-between px-4 mt-4">
        <div>
          <div className="font-bold text-xl text-gray-500 my-4">
            {" "}
            {getMessageByCode(CODE_MSG.APROPOS_EXPERIENCE_TITLE, language)}
          </div>
          <ol className="relative border-l border-gray-200 ">
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white  "></div>
              <time className="mb-1 text-sm font-normal leading-none text-gray-500 ">
                {" "}
                {getMessageByCode(
                  CODE_MSG.APROPOS_EXPERIENCE_IFPEN_DATE,
                  language
                )}
              </time>
              <h3 className="text-lg font-semibold text-gray-900 ">
                {" "}
                {getMessageByCode(
                  CODE_MSG.APROPOS_EXPERIENCE_IFPEN_TITLE,
                  language
                )}
              </h3>
              <p className="mb-4 text-base font-normal text-gray-500 ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_EXPERIENCE_IFPEN_DESCRIPTION,
                  language
                )}
              </p>
            </li>
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white  "></div>
              <time className="mb-1 text-sm font-normal leading-none text-gray-500 ">
                {" "}
                {getMessageByCode(
                  CODE_MSG.APROPOS_EXPERIENCE_EURAFRIC_DATE,
                  language
                )}
              </time>
              <h3 className="text-lg font-semibold text-gray-900 ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_EXPERIENCE_EURAFRIC_TITLE,
                  language
                )}
              </h3>
              <p className="text-base font-normal text-gray-500 ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_EXPERIENCE_EURAFRIC_DESCRIPTION,
                  language
                )}
              </p>
            </li>
            <li className="ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white  "></div>
              <time className="mb-1 text-sm font-normal leading-none text-gray-500 ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_EXPERIENCE_NIMBLEWAYS_DATE,
                  language
                )}
              </time>
              <h3 className="text-lg font-semibold text-gray-900 ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_EXPERIENCE_NIMBLEWAYS_TITLE,
                  language
                )}
              </h3>
              <p className="text-base font-normal text-gray-500 ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_EXPERIENCE_NIMBLEWAYS_DESCRIPTION,
                  language
                )}
              </p>
            </li>
          </ol>
        </div>
        <div>
          <div className="font-bold text-xl text-gray-500 my-4">
            {" "}
            {getMessageByCode(CODE_MSG.APROPOS_FORMATION_TITLE, language)}
          </div>
          <ol className="relative border-l border-gray-200  ">
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white   "></div>
              <time className="mb-1 text-sm font-normal leading-none text-gray-500  ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_FORMATION_EMI_DATE,
                  language
                )}
              </time>
              <h3 className="text-lg font-semibold text-gray-900  ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_FORMATION_EMI_TITLE,
                  language
                )}
              </h3>
              <p className="mb-4 text-base font-normal text-gray-500 ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_FORMATION_EMI_DESCRIPTION,
                  language
                )}
              </p>
            </li>
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>
              <time className="mb-1 text-sm font-normal leading-none text-gray-500  ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_FORMATION_CPGE_DATE,
                  language
                )}
              </time>
              <h3 className="text-lg font-semibold text-gray-900  ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_FORMATION_CPGE_TITLE,
                  language
                )}
              </h3>
              <p className="text-base font-normal text-gray-500  ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_FORMATION_CPGE_DESCRIPTION,
                  language
                )}
              </p>
            </li>
            <li className="ml-4">
              <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white  "></div>
              <time className="mb-1 text-sm font-normal leading-none text-gray-500  ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_FORMATION_BAC_DATE,
                  language
                )}
              </time>
              <h3 className="text-lg font-semibold text-gray-900  ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_FORMATION_BAC_TITLE,
                  language
                )}
              </h3>
              <p className="text-base font-normal text-gray-500 ">
                {getMessageByCode(
                  CODE_MSG.APROPOS_FORMATION_BAC_DESCRIPTION,
                  language
                )}
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};
