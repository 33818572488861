import { Box, Icon, Link } from "@chakra-ui/react";
import React, { useState, useRef, useEffect } from "react";
import { MdHome } from "react-icons/md";
import { MdFacebook, MdMail } from "react-icons/md";
import { FaInstagram, FaPhoneAlt, FaLinkedin } from "react-icons/fa";
import { CustomIcon } from "./CustomIcon";
import logo from "../assets/img/me.png";
import { CODE_MSG, getMessageByCode } from "../utils/Tools";
interface NavBarProps {
  language: "FR" | "EN";
  toggleLanguage: () => void;
}
export const NavBar: React.FC<NavBarProps> = ({ language, toggleLanguage }) => {
  // All states
  const [sticky, setSticky] = useState(false);
  const [navHeight, setNavHeight] = useState(0);

  // All refs
  const navbar = useRef<HTMLDivElement>(null);
  const navbarOffset = useRef<HTMLDivElement>(null);

  // Mount the Event Listener on loading the site
  useEffect(() => {
    const handleScroll = () => {
      // Get the offset to the top

      // Set the boolean value
      setSticky(
        navbarOffset != undefined && navbarOffset.current != undefined
          ? window.pageYOffset >= navbarOffset.current.clientHeight
          : false
      );
    };

    window.addEventListener("scroll", handleScroll);

    // Set the height of the Navbar
    setNavHeight(
      navbar != undefined && navbar.current != undefined
        ? navbar.current.clientHeight
        : 0
    );

    // Remove the listener when cleaning up
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div>
      <div
        ref={navbarOffset}
        className=" flex flex-col md:flex-row justify-around  opacity-80 text-white"
      >
        <div className="flex flex-row justify-around gap-5 p-5">
          <Link href="https://www.linkedin.com/in/errahali/">
            <span>
              {" "}
              <CustomIcon iconType={FaLinkedin} />
            </span>
          </Link>

          <span>
            {" "}
            <CustomIcon iconType={MdFacebook} />
          </span>
          <span>
            <CustomIcon iconType={FaInstagram} />
          </span>
        </div>
        <div className="flex flex-col md:flex-row items-center md:justify-around md:items-baseline my-auto p-4">
          <p className="p-2">
            <CustomIcon iconType={FaPhoneAlt} /> +33 7 80 83 08 18
          </p>
          <p>
            <CustomIcon iconType={MdMail} /> mohamederrahali34@gmail.com
          </p>
        </div>
      </div>
      <nav
        ref={navbar}
        className={` ${
          sticky
            ? " w-full flex md:flex-row justify-between  items-center font-bold bg-black text-white  md:h-16 md:gap-4 w-full fixed top-0 left-0 right-0 z-10   shadow-md"
            : " w-full flex md:flex-row justify-between  items-center font-bold bg-black opacity-50 text-white md:h-16 md:gap-4 "
        }`}
      >
        <div className=" md:h-16  md:w-1/4 flex justify-center hidden md:block">
          <img src={logo} alt="Mohamed ERRAHALI" className=" md:h-16 " />
        </div>

        <ul className="flex flex-wrap md:flex-row justify-around uppercase w-full h-full">
          <Link href={"#home"}>
            <li className="flex p-5  hover:text-orange-500 hover:border-b h-full hover:border-b-orange-500 hover:border-b-4">
              <Icon as={MdHome} boxSize={6} />
            </li>
          </Link>
          {[
            [getMessageByCode(CODE_MSG.NAVBAR_SERVICES, language), "#services"],
            [
              getMessageByCode(CODE_MSG.NAVBAR_COMPETANCES, language),
              "#skills",
            ],
            //  ["Portfolio", "#portfolio"],
            // ["Tarifs", "#tarifs"],
            [getMessageByCode(CODE_MSG.NAVBAR_APROPOS, language), "#about"],
            [getMessageByCode(CODE_MSG.NAVBAR_CONTACT, language), "#contact"],
          ].map((item: string[], index: number) => {
            return (
              <Link
                href={item[1]}
                _hover={{
                  border: "none",
                }}
              >
                <li
                  key={index}
                  className="flex flex-col p-5  hover:text-orange-500 hover:border-b h-full hover:border-b-orange-500 hover:border-b-4 opacity-1"
                >
                  {item[0]}
                </li>
              </Link>
            );
          })}

          <li
            onClick={toggleLanguage}
            className="flex flex-col p-5  hover:text-orange-500 hover:border-b h-full hover:border-b-orange-500 hover:border-b-4"
          >
            {language}
          </li>
        </ul>
      </nav>
    </div>
  );
};
