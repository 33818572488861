import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Select,
  Textarea,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { CODE_MSG, getMessageByCode } from "../utils/Tools";
import { LanguageContext } from "./HomePage";

interface Demande {
  fullName: string;
  categorie: string;
  email: string;
  telephone: string;
  projet: string;
  description: string;
}
export const ContactPage: React.FC = () => {
  const [fullName, setFullName] = useState("");
  const [categorie, setCategorie] = useState("particulier");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [projet, setProjet] = useState("vitrine_standard");
  const [description, setDescription] = useState("");
  const language = useContext(LanguageContext);

  return (
    <div
      id="contact"
      className="p-5 md:p-10 my-auto flex flex-col gap-y-6 h-full  min-h-screen h-full  min-h-screen text-center "
    >
      <div className="uppercase font-bold text-3xl text-black">
        {getMessageByCode(CODE_MSG.CONTACTEZ_MOI_TITLE, language)}
      </div>
      <div className="font-bold text-2xl  text-gray-500">
        {getMessageByCode(CODE_MSG.CONTACTEZ_MOI_DESCRIPTION, language)}
      </div>
      <div className=" flex flex-col pl-2">
        <form className="flex flex-col gap-6" onSubmit={(event) => {}}>
          <div className="flex flex-col md:flex-row w-full justify-between p-4">
            <div className="flex flex-col gap-4 pl-4 mt-4 w-full">
              <div className="font-bold text-xl p-4">
                {" "}
                {getMessageByCode(CODE_MSG.VOS_COORDONNÉES_TITLE, language)}
              </div>
              <FormControl>
                <Input
                  placeholder={getMessageByCode(
                    CODE_MSG.INPUT_FULL_NAME,
                    language
                  )}
                  type="text"
                  name="fullName"
                  required
                  onChange={(e) => setFullName(e.target.value)}
                />
              </FormControl>
              <FormControl as="fieldset">
                <RadioGroup
                  defaultValue="particulier"
                  name="categorie"
                  onChange={setCategorie}
                >
                  <HStack spacing="24px">
                    <Radio value="particulier">
                      {" "}
                      {getMessageByCode(
                        CODE_MSG.RADIO_BOUTTON_PARTICULIER,
                        language
                      )}
                    </Radio>
                    <Radio value=" association">
                      {getMessageByCode(
                        CODE_MSG.RADIO_BOUTTON_ASSOCIATION,
                        language
                      )}
                    </Radio>
                    <Radio value="société">
                      {getMessageByCode(
                        CODE_MSG.RADIO_BOUTTON_SOCIETE,
                        language
                      )}
                    </Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color="gray.500" />
                </InputLeftElement>
                <Input
                  type="email"
                  placeholder={getMessageByCode(CODE_MSG.INPUT_EMAIL, language)}
                  name="email"
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </InputGroup>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <PhoneIcon color="gray.500" />
                </InputLeftElement>
                <Input
                  required
                  type="tel"
                  placeholder={getMessageByCode(
                    CODE_MSG.INPUT_TELEPHONE,
                    language
                  )}
                  name="telephone"
                  onChange={(e) => setTelephone(e.target.value)}
                />
              </InputGroup>
            </div>
            <div className="flex flex-col gap-4 pl-4 mt-4  w-full">
              <div className="font-bold text-xl p-4">
                {getMessageByCode(CODE_MSG.VOTRE_PROJET_TITLE, language)}
              </div>
              <div className="flex flex-col gap-4 p-2 md:p-4">
                <Select
                  variant="filled"
                  name="projet"
                  onChange={(e) => setProjet(e.target.value)}
                >
                  <option selected value="vitrine_standard">
                    {getMessageByCode(
                      CODE_MSG.INPUT_SELECT_SITE_VITRINE_STANDARD,
                      language
                    )}
                  </option>
                  <option value="vitrine_personnalise">
                    {getMessageByCode(
                      CODE_MSG.INPUT_SELECT_SITE_VITRINE_PERSONALISE,
                      language
                    )}
                  </option>
                  <option value="plateforme_ecommerce">
                    {getMessageByCode(
                      CODE_MSG.INPUT_SELECT_SITE_ECOMERCE,
                      language
                    )}
                  </option>
                  <option value="app_mobile">
                    {getMessageByCode(
                      CODE_MSG.INPUT_SELECT_APP_MOBILE,
                      language
                    )}
                  </option>
                  <option value="developpement_specifique">
                    {getMessageByCode(
                      CODE_MSG.INPUT_SELECT_DEV_SPECIFIQUE,
                      language
                    )}
                  </option>
                  <option value="autre">
                    {getMessageByCode(CODE_MSG.INPUT_SELECT_AUTRE, language)}
                  </option>
                </Select>
                <Textarea
                  required
                  name="description"
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder={getMessageByCode(
                    CODE_MSG.DESCREPTION_PROJET,
                    language
                  )}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center ">
            <Button
              w={200}
              colorScheme="orange"
              className="uppercase"
              type={"submit"}
            >
              {" "}
              <a
                href={`mailto:mohamederrahali34@gmail.com?subject=Demande de : ${fullName}, Email : ${email}, Catégorie : ${categorie}, Tel : ${telephone} &body=${description}`}
              >
                {getMessageByCode(CODE_MSG.ENVOYER_DEMANDE, language)}
              </a>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
