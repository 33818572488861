import { Icon } from "@chakra-ui/react";
import { DomaineCompetanceProps } from "../components/DomaineCompetance";
import { CompetanceProps } from "../components/Competance";
import { FaAngular, FaReact } from "react-icons/fa";
import { useContext } from "react";

import { ReactComponent as Angular } from "../assets/svg/angular.svg";
import { ReactComponent as TailwindCss } from "../assets/svg/tailwindcss.svg";
import { ReactComponent as Spring } from "../assets/svg/spring.svg";
import { ReactComponent as NodeJs } from "../assets/svg/nodejs.svg";
import { ReactComponent as Git } from "../assets/svg/git.svg";
import { ReactComponent as GitLab } from "../assets/svg/gitlab.svg";

import { ReactComponent as Docker } from "../assets/svg/docker.svg";
import { ReactComponent as MongoDb } from "../assets/svg/mongodb.svg";
import { ReactComponent as PostgreSql } from "../assets/svg/postgresql.svg";
import { ReactComponent as MySql } from "../assets/svg/mysql.svg";
import { ReactComponent as Oracle } from "../assets/svg/oracle.svg";
import { LanguageContext } from "../pages/HomePage";

export const getMessageByCode = (code: CODE_MSG, language: string) => {
  if (language == "FR" || !language) {
    switch (code) {
      case CODE_MSG.NAVBAR_SERVICES: {
        return "SERVICES";
      }
      case CODE_MSG.NAVBAR_COMPETANCES: {
        return "COMPÉTANCES";
      }
      case CODE_MSG.NAVBAR_APROPOS: {
        return "A PROPOS";
      }
      case CODE_MSG.NAVBAR_CONTACT: {
        return "CONTACT";
      }
      case CODE_MSG.INFO_DEF_NOM: {
        return "Je suis Mohamed ERRAHALI";
      }
      case CODE_MSG.INFO_DEF_METIER: {
        return "Je suis Développeur informatique";
      }
      case CODE_MSG.INFO_DESCREPTION: {
        return `Je vous accompagne dans la création de sites web\n
                    Vitrine, corporate, événementiel, e-commerce`;
      }
      case CODE_MSG.MES_SERVICE_TITLE: {
        return "Mes services";
      }
      case CODE_MSG.MES_SERVICE_DESCREPTION: {
        return "Des prestations adaptées à vos besoins";
      }
      case CODE_MSG.MES_COMPETANCES_TITLE: {
        return "Mes compétances";
      }
      case CODE_MSG.MES_COMPETANCES_DESCREPTION: {
        return "Des compétences à votre service";
      }
      case CODE_MSG.COMPETANCES_EN_DEVELOPPEMENT: {
        return "Compétences en développement";
      }
      case CODE_MSG.DOMAINE_COMPETANCE: {
        return "Domaines de compétences";
      }
      case CODE_MSG.APROPOS_TITLE: {
        return "À PROPOS";
      }
      case CODE_MSG.APROPOS_DESCREPTION: {
        return "Je suis développeur web et j'aime ça !";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_TITLE: {
        return "Exéprience";
      }
      case CODE_MSG.APROPOS_FORMATION_TITLE: {
        return "FORMATION";
      }

      case CODE_MSG.APROPOS_EXPERIENCE_IFPEN_DATE: {
        return "Depuis Juillet 2023";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_IFPEN_TITLE: {
        return " IFP Energies nouvelles, Rueil-Malmaison, Île-de-France, France";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_IFPEN_DESCRIPTION: {
        return "Ingénieur de recherche Génie Logiciel (React, Spring boot, PostgreSql, Docker,CI/CD)";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_NIMBLEWAYS_DATE: {
        return "Février 2022 ---- Juin 2022";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_NIMBLEWAYS_TITLE: {
        return "Nimbleways, Casablanca, Maroc";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_NIMBLEWAYS_DESCRIPTION: {
        return "Stage en développement Informatique ( NextJs/ReactJs, tailwindCSS, Spring boot, PostgreSql, Docker)";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_EURAFRIC_DATE: {
        return "Août 2022 ---- Juillet 2023";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_EURAFRIC_TITLE: {
        return "EURAFRIC INFORMATION, Casablanca, Maroc";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_EURAFRIC_DESCRIPTION: {
        return "Ingénieur Etude et développement (Spring boot, Angular, /JEE)";
      }
      case CODE_MSG.APROPOS_FORMATION_TITLE: {
        return "";
      }
      case CODE_MSG.APROPOS_FORMATION_EMI_DATE: {
        return "Septembre 2019 ---- Juin 2022";
      }
      case CODE_MSG.APROPOS_FORMATION_EMI_TITLE: {
        return "Ecole Mohammadia d'Ingénieurs, Rabat, Maroc";
      }
      case CODE_MSG.APROPOS_FORMATION_EMI_DESCRIPTION: {
        return "Ingénieur d'Etat en informatique, option : Ingénierie et Qualité Logicielles";
      }
      case CODE_MSG.APROPOS_FORMATION_CPGE_DATE: {
        return "Septembre 2017 ---- Juin 2019";
      }
      case CODE_MSG.APROPOS_FORMATION_CPGE_TITLE: {
        return "CPGE IBN GHAZI, Rabat, Maroc";
      }
      case CODE_MSG.APROPOS_FORMATION_CPGE_DESCRIPTION: {
        return "Mathématiques et Physique";
      }
      case CODE_MSG.APROPOS_FORMATION_BAC_DATE: {
        return "Septembre 2016 ---- Juin 2017";
      }
      case CODE_MSG.APROPOS_FORMATION_BAC_TITLE: {
        return "Moulay Baamrane, Kelaat Mgouna, Maroc";
      }
      case CODE_MSG.APROPOS_FORMATION_BAC_DESCRIPTION: {
        return "Baccalauréat en sciences mathématiques A, Mention Bien";
      }
      case CODE_MSG.CONTACTEZ_MOI_TITLE: {
        return "CONTACTEZ-MOI";
      }
      case CODE_MSG.CONTACTEZ_MOI_DESCRIPTION: {
        return "UNE IDÉE ? UN PROJET ? N'HÉSITEZ PAS À ME CONTACTER!";
      }
      case CODE_MSG.VOS_COORDONNÉES_TITLE: {
        return "VOS COORDONNÉES";
      }
      case CODE_MSG.VOTRE_PROJET_TITLE: {
        return "VOTRE PROJET";
      }
      case CODE_MSG.RADIO_BOUTTON_ASSOCIATION: {
        return "ASSOCIATION";
      }
      case CODE_MSG.RADIO_BOUTTON_PARTICULIER: {
        return "Particulier";
      }
      case CODE_MSG.RADIO_BOUTTON_SOCIETE: {
        return "Société";
      }
      case CODE_MSG.DESCREPTION_PROJET: {
        return "Description de votre projet... Merci de détailler le plus possible votre besoin afin de faciliter nos futurs échanges";
      }
      case CODE_MSG.INPUT_FULL_NAME: {
        return "Nom / Prénom";
      }
      case CODE_MSG.INPUT_EMAIL: {
        return "Email";
      }
      case CODE_MSG.INPUT_TELEPHONE: {
        return "Téléphone";
      }
      case CODE_MSG.ENVOYER_DEMANDE: {
        return "ENVOYER MA DEMANDE";
      }

      case CODE_MSG.INPUT_SELECT_SITE_VITRINE_STANDARD: {
        return "Site vitrine [Standard]";
      }
      case CODE_MSG.INPUT_SELECT_SITE_VITRINE_PERSONALISE: {
        return "Site vitrine [Personnalisé]";
      }
      case CODE_MSG.INPUT_SELECT_SITE_ECOMERCE: {
        return "Plateforme e-commerce";
      }
      case CODE_MSG.INPUT_SELECT_APP_MOBILE: {
        return "Application mobile";
      }
      case CODE_MSG.INPUT_SELECT_DEV_SPECIFIQUE: {
        return "Développement spécifique";
      }
      case CODE_MSG.INPUT_SELECT_AUTRE: {
        return "Autre";
      }
      default:
        return "";
    }
  } else {
    switch (code) {
      case CODE_MSG.NAVBAR_SERVICES: {
        return "SERVICES";
      }
      case CODE_MSG.NAVBAR_COMPETANCES: {
        return "SKILLS";
      }
      case CODE_MSG.NAVBAR_APROPOS: {
        return "About";
      }
      case CODE_MSG.NAVBAR_CONTACT: {
        return "CONTACT";
      }
      case CODE_MSG.INFO_DEF_NOM: {
        return "I'm Mohamed ERRAHALI";
      }
      case CODE_MSG.INFO_DEF_METIER: {
        return "I'm a Software Engineer";
      }
      case CODE_MSG.INFO_DESCREPTION: {
        return `I assist you in the creation of websites.\n
        Showcase, corporate, event-based, e-commerce`;
      }
      case CODE_MSG.MES_SERVICE_TITLE: {
        return "My services";
      }
      case CODE_MSG.MES_SERVICE_DESCREPTION: {
        return "Services tailored to your needs";
      }
      case CODE_MSG.MES_COMPETANCES_TITLE: {
        return "My Skills";
      }
      case CODE_MSG.MES_COMPETANCES_DESCREPTION: {
        return "Skills at your service";
      }
      case CODE_MSG.COMPETANCES_EN_DEVELOPPEMENT: {
        return "Development skills";
      }
      case CODE_MSG.DOMAINE_COMPETANCE: {
        return "Areas of expertise";
      }
      case CODE_MSG.APROPOS_TITLE: {
        return "About";
      }
      case CODE_MSG.APROPOS_DESCREPTION: {
        return "I am a web developer and I love it!";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_TITLE: {
        return "Experience";
      }
      case CODE_MSG.APROPOS_FORMATION_TITLE: {
        return "Training";
      }

      case CODE_MSG.APROPOS_EXPERIENCE_IFPEN_DATE: {
        return "Since July 2023";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_IFPEN_TITLE: {
        return "IFP Energies nouvelles, Rueil-Malmaison, Île-de-France, France";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_IFPEN_DESCRIPTION: {
        return "Research Engineer (React, Spring boot, PostgreSql, Docker, CI/CD)";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_NIMBLEWAYS_DATE: {
        return "February 2022 ---- June 2022";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_NIMBLEWAYS_TITLE: {
        return "Nimbleways, Casablanca, Morocco";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_NIMBLEWAYS_DESCRIPTION: {
        return "IT development internship ( NextJs/ReactJs, tailwindCSS, Spring boot, PostgreSql, Docker)";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_EURAFRIC_DATE: {
        return "August 2022 ---- July 2023";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_EURAFRIC_TITLE: {
        return "EURAFRIC INFORMATION, Casablanca, Morocco";
      }
      case CODE_MSG.APROPOS_EXPERIENCE_EURAFRIC_DESCRIPTION: {
        return "R&D Engineer (Spring boot, Angular, /JEE)";
      }
      case CODE_MSG.APROPOS_FORMATION_EMI_DATE: {
        return "September 2019 ---- June 2022";
      }
      case CODE_MSG.APROPOS_FORMATION_EMI_TITLE: {
        return "Mohammadia School of Engineers, Rabat, Morocco";
      }
      case CODE_MSG.APROPOS_FORMATION_EMI_DESCRIPTION: {
        return "Software Engineer, computer science";
      }
      case CODE_MSG.APROPOS_FORMATION_CPGE_DATE: {
        return "September 2017 ---- June 2019";
      }
      case CODE_MSG.APROPOS_FORMATION_CPGE_TITLE: {
        return "IBN GHAZI preparatory classes, Rabat, Morocco";
      }
      case CODE_MSG.APROPOS_FORMATION_CPGE_DESCRIPTION: {
        return "Mathematics and Physics";
      }
      case CODE_MSG.APROPOS_FORMATION_BAC_DATE: {
        return "September 2016 ---- June 2017";
      }
      case CODE_MSG.APROPOS_FORMATION_BAC_TITLE: {
        return "Moulay Baamrane, Kelaat Mgouna, Morocco";
      }
      case CODE_MSG.APROPOS_FORMATION_BAC_DESCRIPTION: {
        return "Baccalaureate in Mathematics A";
      }
      case CODE_MSG.CONTACTEZ_MOI_TITLE: {
        return "CONTACT ME!";
      }
      case CODE_MSG.CONTACTEZ_MOI_DESCRIPTION: {
        return "An idea? A project? Feel free to contact me!";
      }
      case CODE_MSG.VOS_COORDONNÉES_TITLE: {
        return "Your contact information";
      }
      case CODE_MSG.VOTRE_PROJET_TITLE: {
        return "Your project";
      }
      case CODE_MSG.RADIO_BOUTTON_ASSOCIATION: {
        return "Association";
      }
      case CODE_MSG.RADIO_BOUTTON_PARTICULIER: {
        return "Individual";
      }
      case CODE_MSG.RADIO_BOUTTON_SOCIETE: {
        return "Company";
      }
      case CODE_MSG.DESCREPTION_PROJET: {
        return "Description of your project... Please provide as much detail as possible about your requirements to facilitate our future discussions";
      }
      case CODE_MSG.ENVOYER_DEMANDE: {
        return "Submit my request";
      }
      case CODE_MSG.INPUT_FULL_NAME: {
        return "FirstName / LastName";
      }
      case CODE_MSG.INPUT_EMAIL: {
        return "Email";
      }
      case CODE_MSG.INPUT_TELEPHONE: {
        return "Phone";
      }

      case CODE_MSG.INPUT_SELECT_SITE_VITRINE_STANDARD: {
        return "Showcase website [Standard]";
      }
      case CODE_MSG.INPUT_SELECT_SITE_VITRINE_PERSONALISE: {
        return "Showcase website [Custom]";
      }
      case CODE_MSG.INPUT_SELECT_SITE_ECOMERCE: {
        return "e-commerce platform";
      }
      case CODE_MSG.INPUT_SELECT_APP_MOBILE: {
        return "Mobile app";
      }
      case CODE_MSG.INPUT_SELECT_DEV_SPECIFIQUE: {
        return "Specific development";
      }
      case CODE_MSG.INPUT_SELECT_AUTRE: {
        return "Other";
      }
      default:
        return "";
    }
  }
};

export enum CODE_MSG {
  NAVBAR_SERVICES,
  NAVBAR_COMPETANCES,
  NAVBAR_APROPOS,
  NAVBAR_CONTACT,
  INFO_DEF_NOM,
  INFO_DEF_METIER,
  INFO_DESCREPTION,
  MES_SERVICE_TITLE,
  MES_SERVICE_DESCREPTION,
  MES_COMPETANCES_TITLE,
  MES_COMPETANCES_DESCREPTION,
  DOMAINE_COMPETANCE,
  COMPETANCES_EN_DEVELOPPEMENT,
  APROPOS_TITLE,
  APROPOS_DESCREPTION,
  APROPOS_EXPERIENCE_TITLE,
  APROPOS_EXPERIENCE_IFPEN_DATE,
  APROPOS_EXPERIENCE_IFPEN_TITLE,
  APROPOS_EXPERIENCE_IFPEN_DESCRIPTION,
  APROPOS_EXPERIENCE_NIMBLEWAYS_DATE,
  APROPOS_EXPERIENCE_NIMBLEWAYS_TITLE,
  APROPOS_EXPERIENCE_NIMBLEWAYS_DESCRIPTION,
  APROPOS_EXPERIENCE_EURAFRIC_DATE,
  APROPOS_EXPERIENCE_EURAFRIC_TITLE,
  APROPOS_EXPERIENCE_EURAFRIC_DESCRIPTION,
  APROPOS_FORMATION_TITLE,
  APROPOS_FORMATION_EMI_DATE,
  APROPOS_FORMATION_EMI_TITLE,
  APROPOS_FORMATION_EMI_DESCRIPTION,
  APROPOS_FORMATION_CPGE_DATE,
  APROPOS_FORMATION_CPGE_TITLE,
  APROPOS_FORMATION_CPGE_DESCRIPTION,
  APROPOS_FORMATION_BAC_DATE,
  APROPOS_FORMATION_BAC_TITLE,
  APROPOS_FORMATION_BAC_DESCRIPTION,
  CONTACTEZ_MOI_TITLE,
  CONTACTEZ_MOI_DESCRIPTION,
  VOS_COORDONNÉES_TITLE,
  VOTRE_PROJET_TITLE,
  RADIO_BOUTTON_PARTICULIER,
  RADIO_BOUTTON_ASSOCIATION,
  RADIO_BOUTTON_SOCIETE,
  DESCREPTION_PROJET,
  INPUT_FULL_NAME,
  INPUT_EMAIL,
  INPUT_TELEPHONE,
  ENVOYER_DEMANDE,
  INPUT_SELECT_SITE_VITRINE_STANDARD,
  INPUT_SELECT_SITE_VITRINE_PERSONALISE,
  INPUT_SELECT_SITE_ECOMERCE,
  INPUT_SELECT_APP_MOBILE,
  INPUT_SELECT_DEV_SPECIFIQUE,
  INPUT_SELECT_AUTRE,
}

export const getMesDomainesCompetances = (
  language: string
): DomaineCompetanceProps[] => {
  return language == "FR"
    ? [
        {
          title: "GESTION DE PROJETS WEB",
          description:
            "Site vitrine, corporate, évènementiel, e-commerce, intranet, application mobile.",
        },
        {
          title: "INTÉGRATION HTML / CSS",
          description: "Codage à la main respectueux des standards du Web",
        },
        {
          title: "Développement des API",
          description:
            "Développement de la partie backend : JAVA (Spring boot)/ Javascript (NodeJs)",
        },
        {
          title: "DYNAMISE DES PAGES PAR JAVASCRIPT / AJAX",
          description: "jQuery, Prototype, Mootools, Scriptaculous",
        },
        {
          title: "VALIDATION W3C, WAI & RÉFÉRENCEMENT NATUREL SEO",
          description: "Accessibilité & ergonomie des pages web",
        },
        {
          title: "CONCEPTION MULTI-PLATEFORMES",
          description:
            "Compatible tous supports, tablette & application mobile",
        },
      ]
    : [
        {
          title: "WEB PROJECT MANAGEMENT",
          description:
            "Showcase site, corporate, events, e-commerce, intranet, mobile application.",
        },
        {
          title: "HTML/CSS INTEGRATION",
          description: "Hand coding respectful of web standards",
        },
        {
          title: "API development",
          description:
            "Development of the backend part: JAVA (Spring boot)/ Javascript (NodeJs)",
        },
        {
          title: "DYNAMIZE PAGES BY JAVASCRIPT / AJAX",
          description: "jQuery, Prototype, Mootools, Scriptaculous",
        },
        {
          title: "W3C, WAI & NATURAL SEO VALIDATION",
          description: "Accessibility and ergonomics of web pages",
        },
        {
          title: "MULTI-PLATFORM DESIGN",
          description:
            "Compatible with all media, tablet and mobile application",
        },
      ];
};

export const getMesCompétances = (language: string): CompetanceProps[] => {
  if (language == "FR" || !language) {
    return [
      {
        title: "Développement front end : ",

        technologies: [
          {
            icon: <Icon boxSize={8} color={"blue"} as={FaReact} />,
            title: "React",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={FaAngular} />,
            title: "Angular",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={TailwindCss} />,
            title: "TailwindCss",
          },
        ],
      },
      {
        title: "Développement back end : ",

        technologies: [
          {
            icon: <Icon boxSize={8} color={"blue"} as={Spring} />,
            title: "Java/Spring",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={NodeJs} />,
            title: "NodeJs",
          },
        ],
      },
      {
        title: "Gestion de la base de données : ",

        technologies: [
          {
            icon: <Icon boxSize={8} color={"blue"} as={Oracle} />,
            title: "Oracle",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={PostgreSql} />,
            title: "PostgreSql",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={MongoDb} />,
            title: "MongoDb",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={MySql} />,
            title: "MySql",
          },
        ],
      },
      {
        title: "Gestion de projet : ",

        technologies: [
          {
            icon: <Icon boxSize={8} color={"blue"} as={Git} />,
            title: "Git",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={GitLab} />,
            title: "GitLab",
          },
        ],
      },
      {
        title: "CI/CD : ",

        technologies: [
          {
            icon: <Icon boxSize={8} color={"blue"} as={Docker} />,
            title: "Docker",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={GitLab} />,
            title: "GitLab",
          },
        ],
      },
    ];
  } else {
    return [
      {
        title: "Front-end development: ",

        technologies: [
          {
            icon: <Icon boxSize={8} color={"blue"} as={FaReact} />,
            title: "React",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={FaAngular} />,
            title: "Angular",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={TailwindCss} />,
            title: "TailwindCss",
          },
        ],
      },
      {
        title: "Back-end development:",

        technologies: [
          {
            icon: <Icon boxSize={8} color={"blue"} as={Spring} />,
            title: "Java/Spring",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={NodeJs} />,
            title: "NodeJs",
          },
        ],
      },
      {
        title: "Database management: ",

        technologies: [
          {
            icon: <Icon boxSize={8} color={"blue"} as={Oracle} />,
            title: "Oracle",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={PostgreSql} />,
            title: "PostgreSql",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={MongoDb} />,
            title: "MongoDb",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={MySql} />,
            title: "MySql",
          },
        ],
      },
      {
        title: "Project management: ",

        technologies: [
          {
            icon: <Icon boxSize={8} color={"blue"} as={Git} />,
            title: "Git",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={GitLab} />,
            title: "GitLab",
          },
        ],
      },
      {
        title: "CI/CD : ",

        technologies: [
          {
            icon: <Icon boxSize={8} color={"blue"} as={Docker} />,
            title: "Docker",
          },
          {
            icon: <Icon boxSize={8} color={"blue"} as={GitLab} />,
            title: "GitLab",
          },
        ],
      },
    ];
  }
};

export const Tools: React.FC = () => {
  return <div></div>;
};
