import React, { ReactNode } from "react";
import "../assets/scss/HeaderLayout.scss";
interface HeaderLayoutProps {
  children: ReactNode;
}
export const HeaderLayout: React.FC<HeaderLayoutProps> = ({ children }) => {
  const className =
    "bg-[url('https://emeritus.org/wp-content/uploads/2022/10/Featured-Image-95.jpg.optimal.jpg')] bg-center ";
  return (
    <div
      id="home"
      className={
        "h-full overflow-hidden min-h-screen flex flex-col items-stretch relative"
      }
    >
      {children}
      <div
        className={
          className +
          "  bg-cover bg-image h-full min-w-screen  min-h-full bg-no-repeat"
        }
      ></div>
    </div>
  );
};
