import React, { ReactNode } from "react";
export enum IconPlace {
  RIGHT = "right",
  LEFT = "left",
}
export interface ServiceProps {
  icon: ReactNode;
  title: string;
  description: string;
  iconPlace?: string;
}
export const Service: React.FC<ServiceProps> = ({
  icon,
  title,
  description,
  iconPlace,
}) => {
  return iconPlace == IconPlace.RIGHT ? (
    <div className="text-black text-end flex flex-row justify-between items-center  ">
      <div className="flex flex-col flex-grow relative w-40 p-2">
        <div className="flex p-2 justify-end uppercase my-auto font-bold text-end text-md">
          {title}
        </div>
        <div className="flex text-md p-2 justify-end uppercase  text-end  my-auto text-gray-500 font-semibold">
          {description}
        </div>
      </div>
      <div className=" flex items-center justify-center rounded-full h-20 w-20 border-2  border-red-500 hover:bg-red-500">
        {icon}
      </div>
    </div>
  ) : (
    <div className="text-black flex flex-row justify-between items-center ">
      <div className=" flex items-center justify-center rounded-full h-20 w-20 border-2  border-red-500  hover:bg-red-500">
        {icon}
      </div>
      <div className="flex flex-col items-start flex-grow w-40">
        <div className="flex p-2 justify-start uppercase my-auto font-bold text-start text-md">
          {title}
        </div>
        <div className="flex p-2 text-md text-start justify-start uppercase my-auto text-gray-500 font-semibold">
          {description}
        </div>
      </div>
    </div>
  );
};
