import React, { useState } from "react";
import { Icon } from "@chakra-ui/react";
import { IconType } from "react-icons/lib";

interface CustomIconProps {
  iconType: IconType;
}
export const CustomIcon: React.FC<CustomIconProps> = ({ iconType }) => {
  const [hover, setHover] = useState(false);

  return (
    <Icon
      className="cursor-pointer"
      as={iconType}
      boxSize={6}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      color={hover ? "red" : "white"}
    />
  );
};
