import React, { useEffect, useState, createContext } from "react";
import { Header } from "../components/Header";
import { NavBar } from "../components/NavBar";
import { MyInfo } from "../components/MyInfo";
import { HeaderLayout } from "../layouts/HeaderLayout";
import { MesSerices } from "./MesServices";
import { MesCompetances } from "./MesCompetances";
import { Apropos } from "./Apropos";
import { ContactPage } from "./ContactPage";
import { RedirectPage } from "./RedirectPage";
import { MyInfoEn } from "../components/MyInfoEn";
export const LanguageContext = createContext("FR");

export const HomePage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState<"FR" | "EN">("FR");
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return isLoading ? (
    <RedirectPage />
  ) : (
    <>
      <LanguageContext.Provider value={language}>
        <HeaderLayout>
          <NavBar
            language={language}
            toggleLanguage={() => {
              setLanguage((prev) => (prev == "FR" ? "EN" : "FR"));
            }}
          />
          {language == "FR" ? <MyInfo /> : <MyInfoEn />}
        </HeaderLayout>
        <MesSerices />
        <MesCompetances />
        <Apropos />
        <ContactPage />
        <Header />
      </LanguageContext.Provider>
    </>
  );
};
