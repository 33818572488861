import React, { useContext } from "react";
import { IconPlace, Service, ServiceProps } from "../components/Service";
import { Icon } from "@chakra-ui/react";
import { EditIcon, RepeatIcon, SettingsIcon, StarIcon } from "@chakra-ui/icons";
import { FaRegCheckSquare, FaRegLightbulb } from "react-icons/fa";
import { MdRepeat, MdSettingsSuggest } from "react-icons/md";
import { CODE_MSG, getMessageByCode } from "../utils/Tools";
import { LanguageContext } from "./HomePage";

export const MesSerices: React.FC = () => {
  const mesServicesRight: ServiceProps[] = [
    {
      title: "DYNAMISME DES PAGES",
      icon: (
        <Icon
          _hover={{
            color: "white",
          }}
          as={RepeatIcon}
          style={{
            width: "50%",
            height: "50%",
            borderRadius: "100%",
          }}
        />
      ),
      description:
        "Des animations de contenu non intrusives,pour embellir votre projet.",
    },
    {
      title: "INTERFACE D'ADMINISTRATION",
      icon: (
        <Icon
          _hover={{
            color: "white",
          }}
          as={SettingsIcon}
          style={{
            width: "50%",
            height: "50%",
            borderRadius: "100%",
          }}
        />
      ),
      description:
        "Outils spécifiques au bon fonctionnement de votre entreprise.",
    },
    {
      title: "RESPONSIVE DESIGN",
      icon: (
        <Icon
          _hover={{
            color: "white",
          }}
          as={MdRepeat}
          style={{
            width: "50%",
            height: "50%",
            borderRadius: "100%",
          }}
        />
      ),
      description: "Compatible tous supports, tablette & application mobile.",
    },
  ];
  const mesServicesLeft: ServiceProps[] = [
    {
      title: "Développement de projet web",
      icon: (
        <Icon
          _hover={{
            color: "white",
          }}
          as={FaRegLightbulb}
          style={{
            width: "50%",
            height: "50%",
            borderRadius: "100%",
          }}
        />
      ),
      description:
        "Site vitrine, corporate, évènementiel,e-commerce, intranet, application mobile",
    },
    {
      title: "INTÉGRATION WEB",
      icon: (
        <Icon
          _hover={{
            color: "white",
          }}
          as={EditIcon}
          style={{
            width: "50%",
            height: "50%",
            borderRadius: "100%",
          }}
        />
      ),
      description:
        "Des intégrations (X)HTML / CSS respectueuses des standards du Web",
    },
    {
      title: "DÉVELOPPEMENTS SPÉCIFIQUES",
      icon: (
        <Icon
          _hover={{
            color: "white",
          }}
          as={MdSettingsSuggest}
          style={{
            width: "50%",
            height: "50%",
            borderRadius: "100%",
          }}
        />
      ),
      description:
        "Des outils adaptés à votre coeur de métier applications & solutions personnalisées",
    },
    {
      title: "RÉFÉRENCEMENT NATUREL",
      icon: (
        <Icon
          _hover={{
            color: "white",
          }}
          as={FaRegCheckSquare}
          style={{
            width: "50%",
            height: "50%",
            borderRadius: "100%",
          }}
        />
      ),
      description:
        "Affichage sémantique des informations,des pages propres pour un référencement optimal",
    },
  ];

  const mesServicesRightEN: ServiceProps[] = [
    {
      title: "PAGE DYNAMISM",
      icon: (
        <Icon
          _hover={{
            color: "white",
          }}
          as={RepeatIcon}
          style={{
            width: "50%",
            height: "50%",
            borderRadius: "100%",
          }}
        />
      ),
      description:
        "Non-intrusive content animations, to embellish your project.",
    },
    {
      title: "ADMINISTRATION INTERFACE",
      icon: (
        <Icon
          _hover={{
            color: "white",
          }}
          as={SettingsIcon}
          style={{
            width: "50%",
            height: "50%",
            borderRadius: "100%",
          }}
        />
      ),
      description: "Tools specific to the proper functioning of your business.",
    },
    {
      title: "RESPONSIVE DESIGN",
      icon: (
        <Icon
          _hover={{
            color: "white",
          }}
          as={MdRepeat}
          style={{
            width: "50%",
            height: "50%",
            borderRadius: "100%",
          }}
        />
      ),
      description: "Compatible with all media, tablet and mobile application.",
    },
  ];
  const mesServicesLeftEN: ServiceProps[] = [
    {
      title: "Web project development",
      icon: (
        <Icon
          _hover={{
            color: "white",
          }}
          as={FaRegLightbulb}
          style={{
            width: "50%",
            height: "50%",
            borderRadius: "100%",
          }}
        />
      ),
      description:
        "Showcase site, corporate, event, e-commerce, intranet, mobile application",
    },
    {
      title: "WEB INTEGRATION",
      icon: (
        <Icon
          _hover={{
            color: "white",
          }}
          as={EditIcon}
          style={{
            width: "50%",
            height: "50%",
            borderRadius: "100%",
          }}
        />
      ),
      description: "HTML / CSS integrations respectful of web standards",
    },
    {
      title: "SPECIFIC DEVELOPMENTS",
      icon: (
        <Icon
          _hover={{
            color: "white",
          }}
          as={MdSettingsSuggest}
          style={{
            width: "50%",
            height: "50%",
            borderRadius: "100%",
          }}
        />
      ),
      description:
        "Tools adapted to your core business applications & personalized solutions",
    },
    {
      title: "NATURAL REFERENCE",
      icon: (
        <Icon
          _hover={{
            color: "white",
          }}
          as={FaRegCheckSquare}
          style={{
            width: "50%",
            height: "50%",
            borderRadius: "100%",
          }}
        />
      ),
      description:
        "Semantic display of information, clean pages for optimal SEO",
    },
  ];

  const language = useContext(LanguageContext);
  return (
    <div
      id="services"
      className="text-white text-center mg-white my-auto flex flex-col items-center justify-start md:pt-14  gap-y-6 h-full min-h-screen"
    >
      <div className="uppercase font-bold text-3xl text-black">
        {getMessageByCode(CODE_MSG.MES_SERVICE_TITLE, language)}
      </div>
      <div className="font-bold text-2xl  text-gray-500">
        {getMessageByCode(CODE_MSG.MES_SERVICE_DESCREPTION, language)}
      </div>
      {/*<div className="my-5 text-black uppercase">
        <div className="w-full flex flex-row justify-center gap-5 ">
          <div className="hover:bg-orange-500 p-2 rounded-md hover:text-white font-bold">
            TOUS LES PROJETS
          </div>

          <div className="hover:bg-orange-500 p-2 rounded-md hover:text-white font-bold">
            Développement web
          </div>
        </div>
  </div>*/}
      <div className="flex flex-wrap justify-center w-full gap-2 mx-4  items-center">
        <div className="flex flex-col gap-4 flex-grow">
          {language == "FR"
            ? mesServicesLeft.map((item, index: number) => {
                return (
                  <Service
                    iconPlace={IconPlace.RIGHT}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                  />
                );
              })
            : mesServicesLeftEN.map((item, index: number) => {
                return (
                  <Service
                    iconPlace={IconPlace.RIGHT}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                  />
                );
              })}
        </div>
        <div className="border-2 h-60 w-80 relative flex">
          <img src="https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y29tcHV0ZXJ8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60" />
        </div>
        <div className="flex flex-col  gap-4  flex-grow ">
          {language == "FR"
            ? mesServicesRight.map((item, index: number) => {
                return (
                  <Service
                    iconPlace={IconPlace.LEFT}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                  />
                );
              })
            : mesServicesRightEN.map((item, index: number) => {
                return (
                  <Service
                    iconPlace={IconPlace.LEFT}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
};
